import React, { useEffect, useState } from 'react'
// images
import logo from '../../../assets/img/logo.png';
// services
import CompanyService from '../../../services/company';
import CentersCostsService from '../../../services/centersCosts';
import GeneralService from '../../../services/general';
import ExternalService from '../../../services/external';
import WorkerService from '../../../services/worker';
import ServiceService from '../../../services/service';
// Functions
import { CODES } from '../../../utils/responseCodes';
import { Toast } from '../../../utils/alerts';
// Components
import Loader from '../../components/Spinner/Loader';
import MainSelect from '../../components/general/MainSelect';
import MainInput from '../../components/general/MainInput';
// Utils
import _ from 'lodash';

const newService = {
    empresa_id: null,
    centroCosto_id: null,
    tipoComprobante_id: 1,
    condicionPago_id: 1,
    moneda_id: 1,
    importeSinIGV: 0,
    importeTotal: 0,
    servicio: null,
    persona_id: null,
    documento: null,
    nombres: null,
    telefono: null,
    email: null,
    emailAdd: null,
}

const FormBillingExternalServices = () => {
    const [openLoader, setOpenLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(null);

    const IGV = 0.18;
    const [checkIGV, setCheckIGV] = useState(true);
    const [infoService, setInfoService] = useState(newService);
    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        let updData = _.cloneDeep(infoService);
        updData.nombres = '';
        updData.telefono = '';
        updData.email = '';
        updData.emailAdd = '';
        setInfoService(updData);
    }, [infoService.documento])

    useEffect(() => {
        let totalTmp = 0
        if (checkIGV)
            totalTmp = parseFloat(infoService.importeSinIGV) + parseFloat((infoService.importeSinIGV * IGV))
        else
            totalTmp = parseFloat(infoService.importeSinIGV)
        let updData = _.cloneDeep(infoService);
        updData.importeTotal = totalTmp?.toFixed(2);
        setInfoService(updData);
    }, [infoService.importeSinIGV, checkIGV])

    const init = async () => {
        Promise.all([
            getEmpresas(),
            getCentrocosto(),
            getMoneda(),
            getCondicionPago(),
            getTipoComprobante(),
        ]).then(() => setOpenLoader(false));
    }

    const [companies, setCompanies] = useState(null);
    const [centersCosts, setCentersCosts] = useState(null);
    const [moneda, setMoneda] = useState(null);
    const [paymentCondition, setPaymentCondition] = useState(null);
    const [voucherType, setVoucherType] = useState(null);

    const getEmpresas = async () => {
        try {
            const result = await CompanyService.getEmpresas();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_empresa
                    element.value = element.id_empresa
                    element.label = element.desEmpresa
                })
                setCompanies(result.data);
            }
        } catch (error) {
            console.log(`Error en getEmpresas: ${error}`);
        }
    }

    const getCentrocosto = async () => {
        try {
            const result = await CentersCostsService.getCentrocosto();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_centroCosto
                    element.value = element.id_centroCosto
                    element.label = element.names
                })
                setCentersCosts(result.data);
            }
        } catch (error) {
            console.log(`Error en getCentrocosto: ${error}`);
        }
    }

    const getMoneda = async () => {
        try {
            const result = await GeneralService.getMoneda();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_moneda
                    element.value = element.id_moneda
                    element.label = element.descripcion
                })
                setMoneda(result.data);
            }
        } catch (error) {
            console.log(`Error en getMoneda: ${error}`);
        }
    }

    const getCondicionPago = async () => {
        try {
            const result = await GeneralService.getCondicionPago();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_condicionPago
                    element.value = element.id_condicionPago
                    element.label = element.descripcion
                })
                setPaymentCondition(result.data);
            }
        } catch (error) {
            console.log(`Error en getCondicionPago: ${error}`);
        }
    }

    const getTipoComprobante = async () => {
        try {
            const result = await GeneralService.getTipoComprobante();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_tipoComprobante
                    element.value = element.id_tipoComprobante
                    element.label = element.descripcion
                })
                setVoucherType(result.data);
            }
        } catch (error) {
            console.log(`Error en getTipoComprobante: ${error}`);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            searchPerson();
        }
    };

    const searchPerson = async () => {
        try {
            setOpenLoader(true);
            setMessageLoader('Buscando información ...');
            let exito = 0;

            if (!infoService?.documento || infoService?.documento?.length < 8 || infoService?.documento?.length > 11) {
                setInfoService(newService);
                Toast.fire({
                    icon: 'warning',
                    title: 'Debe ingresar un documento valido!</br><b>DNI(08 dig.) -  RUC(11 dig.)</b>'
                });
                return;
            }

            let updData = _.cloneDeep(infoService);
            let flag = 0;

            const resultLP = await WorkerService.getTrabajador(infoService.documento);

            if (resultLP.status === CODES.SUCCESS_200) {
                const worker = resultLP.data;
                updData.persona_id = worker.id_persona;
                updData.documento = worker.numdoc;
                updData.nombres = `${worker.apePaterno} ${worker.apeMaterno}, ${worker.nombres}`;
                updData.telefono = worker.telefono || null;
                updData.email = worker.email || null;
                updData.emailAdd = worker.emailAdd || null;
                flag = 1;
                exito = 1;
            }

            if (flag === 0 && infoService?.documento?.length === 8) {
                const resultDNI = await ExternalService.apiDNI(infoService.documento);

                if (resultDNI.status === CODES.SUCCESS_200 && Object.keys(resultDNI.data).length > 0) {
                    const worker = resultDNI.data;
                    updData.documento = worker.numeroDocumento;
                    updData.nombres = `${worker.nombre}`;
                    updData.telefono = worker.telefono || null;
                    updData.email = worker.email || null;
                    updData.emailAdd = worker.emailAdd || null;
                    exito = 1;
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: resultDNI?.data?.error || 'Error al buscar el DNI'
                    });

                }

                flag = 1;
            }

            if (flag === 0 && infoService?.documento?.length === 11) {
                const resultRUC = await ExternalService.apiRUC(infoService.documento);

                if (resultRUC.status === CODES.SUCCESS_200 && Object.keys(resultRUC.data).length > 0) {
                    const worker = resultRUC.data;
                    updData.documento = worker.numeroDocumento;
                    updData.nombres = `${worker.nombre}`;
                    updData.telefono = worker.telefono || null;
                    updData.email = worker.email || null;
                    updData.emailAdd = worker.emailAdd || null;
                    exito = 1;
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: resultRUC?.data?.error || 'Error al buscar el RUC'
                    });

                }
            }

            // Actualizar el estado solo si la búsqueda fue exitosa
            if (exito === 1) {
                setInfoService(prevInfo => ({ ...prevInfo, ...updData }));
                Toast.fire({
                    icon: 'success',
                    title: 'Información encontrada!'
                });
            }
        } catch (error) {
            console.log(`Error en searchPerson: ${error}`);
        } finally {
            setOpenLoader(false);
            setMessageLoader(null);
        }
    }

    const sentForm = async (e) => {
        e.preventDefault();
        try {
            if (!infoService?.empresa_id || infoService?.empresa_id === 0) {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe selecionar una empresa"
                })
                return;
            }
            if (!infoService?.centroCosto_id || infoService?.centroCosto_id === 0) {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe selecionar un centro de costo"
                })
                return;
            }
            if (!infoService?.tipoComprobante_id || infoService?.tipoComprobante_id === 0) {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe selecionar un tipo de comprobante"
                })
                return;
            }
            if (!infoService?.condicionPago_id || infoService?.condicionPago_id === 0) {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe selecionar una condición de pago"
                })
                return;
            }
            if (!infoService?.moneda_id || infoService?.moneda_id === 0) {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe selecionar una moneda"
                })
                return;
            }
            if (!infoService?.importeSinIGV || infoService?.importeSinIGV === 0) {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe Ingresar un monto"
                })
                return;
            }
            if (!infoService?.servicio || infoService?.servicio === '') {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe ingresar un descripcion del servicio"
                })
                return;
            }
            if (!infoService?.documento || infoService?.documento === '') {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe ingresar un numero de documento"
                })
                return;
            }
            if (!infoService?.nombres || infoService?.nombres === '') {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe buscar la razon social por el numero de documento"
                })
                return;
            }
            if (!infoService?.telefono || infoService?.telefono === '') {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe ingresar un número de telefono"
                })
                return;
            }
            if (!infoService?.email || infoService?.email === '') {
                Toast.fire({
                    icon: 'warning',
                    title: "Debe ingresar un correo"
                })
                return;
            }
            setOpenLoader(true);
            setMessageLoader('Guardando información ...');

            const result = await ServiceService.createServicio(infoService);
            if (result.status === CODES.CREATE_201) {
                Toast.fire({
                    icon: 'success',
                    title: result?.data?.message || "Servicio guardado"
                })
                setInfoService(newService);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: result?.response?.data?.error || "Error al guardar"
                })
            }
        } catch (error) {
            console.log(`Error en createServicio: ${error}`);
        } finally {
            setOpenLoader(false);
            setMessageLoader(null);
        }
    }

    /**/
    const containerStyles = {
        position: 'relative',
        width: '90%',
        maxWidth: '900px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        margin: '0 auto',
        marginTop: '25px',
        border: 'solid 1px #FF9CA6',
        minHeight: '80vh',
        overflowY: 'auto'
    };

    const circleStyles = {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        bottom: 0,
        right: 0,
    };

    const formStyles = {
        position: 'absolute',
        margin: '20px 20px 10px 20px',
    };

    return (
        <div style={containerStyles}>
            <div style={circleStyles}>
                <span className="d q aa fa">
                    <svg width="126" height="156" viewBox="0 0 126 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M62.9999 126C97.8032 126 126 97.7947 126 63C126 28.2053 97.8032 0 62.9999 0C28.2137 0 -6.10352e-05 28.2053 -6.10352e-05 63C-6.10352e-05 97.7947 28.2137 126 62.9999 126Z" fill="url(#paint0_radial_100_549)"></path>
                        <circle cx="45.3482" cy="119.68" r="1.72625" transform="rotate(-120 45.3482 119.68)" fill="#8B5CF6"></circle>
                        <circle cx="71.3609" cy="104.662" r="1.72625" transform="rotate(-120 71.3609 104.662)" fill="#8B5CF6"></circle>
                        <circle cx="32.7905" cy="126.931" r="1.72625" transform="rotate(-120 32.7905 126.931)" fill="#8B5CF6"></circle>
                        <circle cx="58.2051" cy="112.258" r="1.72625" transform="rotate(-120 58.2051 112.258)" fill="#8B5CF6"></circle>
                        <circle cx="84.2176" cy="97.2391" r="1.72625" transform="rotate(-120 84.2176 97.2391)" fill="#8B5CF6"></circle>
                        <circle cx="60.3668" cy="145.693" r="1.72625" transform="rotate(-120 60.3668 145.693)" fill="#8B5CF6"></circle>
                        <circle cx="37.7528" cy="106.525" r="1.72625" transform="rotate(-120 37.7528 106.525)" fill="#8B5CF6"></circle>
                        <circle cx="86.3793" cy="130.675" r="1.72625" transform="rotate(-120 86.3793 130.675)" fill="#8B5CF6"></circle>
                        <circle cx="63.7654" cy="91.5066" r="1.72625" transform="rotate(-120 63.7654 91.5066)" fill="#8B5CF6"></circle>
                        <circle cx="47.809" cy="152.944" r="1.72625" transform="rotate(-120 47.809 152.944)" fill="#8B5CF6"></circle>
                        <circle cx="25.195" cy="113.775" r="1.72625" transform="rotate(-120 25.195 113.775)" fill="#8B5CF6"></circle>
                        <circle cx="73.2235" cy="138.27" r="1.72625" transform="rotate(-120 73.2235 138.27)" fill="#8B5CF6"></circle>
                        <circle cx="50.6096" cy="99.1019" r="1.72625" transform="rotate(-120 50.6096 99.1019)" fill="#8B5CF6"></circle>
                        <circle cx="99.2361" cy="123.252" r="1.72625" transform="rotate(-120 99.2361 123.252)" fill="#8B5CF6"></circle>
                        <circle cx="76.6221" cy="84.0833" r="1.72625" transform="rotate(-120 76.6221 84.0833)" fill="#8B5CF6"></circle>
                        <circle cx="52.7713" cy="132.537" r="1.72625" transform="rotate(-120 52.7713 132.537)" fill="#8B5CF6"></circle>
                        <circle cx="30.1573" cy="93.3689" r="1.72625" transform="rotate(-120 30.1573 93.3689)" fill="#8B5CF6"></circle>
                        <circle cx="78.7839" cy="117.519" r="1.72625" transform="rotate(-120 78.7839 117.519)" fill="#8B5CF6"></circle>
                        <circle cx="56.17" cy="78.3504" r="1.72625" transform="rotate(-120 56.17 78.3504)" fill="#8B5CF6"></circle>
                        <circle cx="40.2136" cy="139.788" r="1.72625" transform="rotate(-120 40.2136 139.788)" fill="#8B5CF6"></circle>
                        <circle cx="17.5996" cy="100.619" r="1.72625" transform="rotate(-120 17.5996 100.619)" fill="#8B5CF6"></circle>
                        <circle cx="65.6281" cy="125.115" r="1.72625" transform="rotate(-120 65.6281 125.115)" fill="#8B5CF6"></circle>
                        <circle cx="43.0141" cy="85.9466" r="1.72625" transform="rotate(-120 43.0141 85.9466)" fill="#8B5CF6"></circle>
                        <circle cx="91.6406" cy="110.096" r="1.72625" transform="rotate(-120 91.6406 110.096)" fill="#8B5CF6"></circle>
                        <circle cx="69.0267" cy="70.9275" r="1.72625" transform="rotate(-120 69.0267 70.9275)" fill="#8B5CF6"></circle>
                        {/* <defs>
                            <radialGradient id="paint0_radial_100_549" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.9999) rotate(90) scale(126)">
                                <stop stopColor="white"></stop>
                                <stop offset="0.569" stopColor="#E4F2FE"></stop>
                                <stop offset="0.993" stopColor="#D2E2EF"></stop>
                            </radialGradient>
                        </defs> */}
                        <defs>
                            <radialGradient id="paint0_radial_100_549" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.9999) rotate(90) scale(126)">
                                <stop stopColor="white"></stop>
                                <stop offset="0.569" stopColor="#E3E7FF"></stop>
                                <stop offset="0.993" stopColor="#CFD3E9"></stop>
                            </radialGradient>
                        </defs>
                    </svg>
                </span>
                <span className="d ba ca fa">
                    <svg width="161" height="189" viewBox="0 0 161 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M160.578 80.5879C160.578 36.3933 124.762 0.587885 80.5784 0.587887C36.3946 0.587889 0.578432 36.3933 0.578434 80.5879C0.578436 124.761 36.3947 160.588 80.5784 160.588C124.762 160.588 160.578 124.761 160.578 80.5879Z" fill="url(#paint0_radial_100_548)"></path>
                        <circle cx="93.1069" cy="153.111" r="1.72625" transform="rotate(-120 93.1069 153.111)" fill="#8B5CF6"></circle>
                        <circle cx="119.12" cy="138.093" r="1.72625" transform="rotate(-120 119.12 138.093)" fill="#8B5CF6"></circle>
                        <circle cx="80.5492" cy="160.362" r="1.72625" transform="rotate(-120 80.5492 160.362)" fill="#8B5CF6"></circle>
                        <circle cx="105.964" cy="145.688" r="1.72625" transform="rotate(-120 105.964 145.688)" fill="#8B5CF6"></circle>
                        <circle cx="131.976" cy="130.67" r="1.72625" transform="rotate(-120 131.976 130.67)" fill="#8B5CF6"></circle>
                        <circle cx="108.125" cy="179.124" r="1.72625" transform="rotate(-120 108.125 179.124)" fill="#8B5CF6"></circle>
                        <circle cx="85.5115" cy="139.955" r="1.72625" transform="rotate(-120 85.5115 139.955)" fill="#8B5CF6"></circle>
                        <circle cx="134.138" cy="164.105" r="1.72625" transform="rotate(-120 134.138 164.105)" fill="#8B5CF6"></circle>
                        <circle cx="111.524" cy="124.937" r="1.72625" transform="rotate(-120 111.524 124.937)" fill="#8B5CF6"></circle>
                        <circle cx="95.5677" cy="186.374" r="1.72625" transform="rotate(-120 95.5677 186.374)" fill="#8B5CF6"></circle>
                        <circle cx="72.9537" cy="147.206" r="1.72625" transform="rotate(-120 72.9537 147.206)" fill="#8B5CF6"></circle>
                        <circle cx="120.982" cy="171.701" r="1.72625" transform="rotate(-120 120.982 171.701)" fill="#8B5CF6"></circle>
                        <circle cx="98.3683" cy="132.533" r="1.72625" transform="rotate(-120 98.3683 132.533)" fill="#8B5CF6"></circle>
                        <circle cx="146.995" cy="156.682" r="1.72625" transform="rotate(-120 146.995 156.682)" fill="#8B5CF6"></circle>
                        <circle cx="124.381" cy="117.514" r="1.72625" transform="rotate(-120 124.381 117.514)" fill="#8B5CF6"></circle>
                        <circle cx="100.53" cy="165.968" r="1.72625" transform="rotate(-120 100.53 165.968)" fill="#8B5CF6"></circle>
                        <circle cx="77.916" cy="126.8" r="1.72625" transform="rotate(-120 77.916 126.8)" fill="#8B5CF6"></circle>
                        <circle cx="126.543" cy="150.95" r="1.72625" transform="rotate(-120 126.543 150.95)" fill="#8B5CF6"></circle>
                        <circle cx="103.929" cy="111.781" r="1.72625" transform="rotate(-120 103.929 111.781)" fill="#8B5CF6"></circle>
                        <circle cx="87.9723" cy="173.219" r="1.72625" transform="rotate(-120 87.9723 173.219)" fill="#8B5CF6"></circle>
                        <circle cx="65.3583" cy="134.05" r="1.72625" transform="rotate(-120 65.3583 134.05)" fill="#8B5CF6"></circle>
                        <circle cx="113.387" cy="158.546" r="1.72625" transform="rotate(-120 113.387 158.546)" fill="#8B5CF6"></circle>
                        <circle cx="90.7728" cy="119.377" r="1.72625" transform="rotate(-120 90.7728 119.377)" fill="#8B5CF6"></circle>
                        <circle cx="139.399" cy="143.527" r="1.72625" transform="rotate(-120 139.399 143.527)" fill="#8B5CF6"></circle>
                        <circle cx="116.785" cy="104.358" r="1.72625" transform="rotate(-120 116.785 104.358)" fill="#8B5CF6"></circle>
                        {/* <defs>
                            <radialGradient id="paint0_radial_100_548" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.578434 80.5879) scale(160)">
                                <stop stopColor="white"></stop>
                                <stop offset="0.569" stopColor="#FFEEFE"></stop>
                                <stop offset="0.993" stopColor="#F1C6EF"></stop>
                            </radialGradient>
                        </defs> */}
                        <defs>
                            <radialGradient id="paint0_radial_100_548" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(0.578434 80.5879) scale(160)">
                                <stop stopColor="white"></stop>
                                <stop offset="0.569" stopColor="#FFE6E7"></stop>
                                <stop offset="0.993" stopColor="#F1CACB"></stop>
                            </radialGradient>
                        </defs>
                    </svg>
                </span>
            </div>
            <div style={formStyles}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.2rem' }}>
                    <img src={logo} alt="Logo" width={150} />
                </div>
                <form onSubmit={sentForm} >
                    <div className='row'>
                        <div className="col-md-6 mt-3">
                            <MainSelect
                                title={'Empresa'}
                                name={'empresa_id'}
                                value={infoService.empresa_id || 0}
                                onChange={(e) => { setInfoService({ ...infoService, empresa_id: parseInt(e.target.value, 10) }) }}
                                options={companies}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainSelect
                                title={'Centro de costos'}
                                name={'centroCosto_id'}
                                value={infoService.centroCosto_id || 0}
                                onChange={(e) => { setInfoService({ ...infoService, centroCosto_id: parseInt(e.target.value, 10) }) }}
                                options={centersCosts}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainSelect
                                title={'Tipo de documento'}
                                name={'tipoComprobante_id'}
                                value={infoService.tipoComprobante_id || 0}
                                onChange={(e) => { setInfoService({ ...infoService, tipoComprobante_id: parseInt(e.target.value, 10) }) }}
                                options={voucherType}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainSelect
                                title={'Condición de pago'}
                                name={'condicionPago_id'}
                                value={infoService.condicionPago_id || 0}
                                onChange={(e) => { setInfoService({ ...infoService, condicionPago_id: parseInt(e.target.value, 10) }) }}
                                options={paymentCondition}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainSelect
                                title={'Moneda'}
                                name={'moneda_id'}
                                value={infoService.moneda_id || 0}
                                onChange={(e) => { setInfoService({ ...infoService, moneda_id: parseInt(e.target.value, 10) }) }}
                                options={moneda}
                                required={true}
                            />
                        </div>
                        <div className="col-md-4 col-sm-6 mt-3">
                            <MainInput
                                title={'Importe (sin IGV)'}
                                value={infoService.importeSinIGV}
                                name={'importeSinIGV'}
                                type={'number'}
                                onChange={(e) => { setInfoService({ ...infoService, importeSinIGV: e.target.value }) }}
                                required={true}
                                step={'0.01'}
                                min={'0'}
                            />
                        </div>
                        <div className="col-md-2 col-sm-6 mt-3">
                            <div className='d-flex justify-content-between align-items-end'>
                                <MainInput
                                    title={'Total'}
                                    value={infoService.importeTotal}
                                    type={'number'}
                                    readOnly={true}
                                    disabled={true}
                                    required={false}
                                />
                                &nbsp;
                                <label style={{ fontSize: 'small', textAlign: 'center', fontWeight: 'bolder', }}>
                                    IGV<input type="checkbox" id="cbox1" value="first_checkbox" checked={checkIGV} onChange={() => setCheckIGV(!checkIGV)} />
                                </label>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-12 mt-3">
                            <MainInput
                                title={'Descripción de servicio'}
                                value={infoService.servicio}
                                name={'servicio'}
                                type={'text'}
                                onChange={(e) => { setInfoService({ ...infoService, servicio: e.target.value }) }}
                                required={true}
                            />
                        </div>
                        <div className="col-sm-6 col-md-3 mt-3">
                            <div className='d-flex justify-content-evenly align-items-end'>
                                <MainInput
                                    title={'RUC o DNI'}
                                    value={infoService.documento}
                                    name={'documento'}
                                    type={'text'}
                                    onChange={(e) => { setInfoService({ ...infoService, documento: e.target.value }) }}
                                    handleKeyPress={handleKeyPress}
                                    required={true}
                                />
                                &nbsp;
                                <button
                                    type='button'
                                    className='btn btn-outline-dark btn-sm px-1 py-0'
                                    title='Buscar persona'
                                    onClick={searchPerson}
                                >
                                    &#128269;
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainInput
                                title={'Razon Social o Apellidos y Nombres'}
                                value={infoService.nombres}
                                name={'nombres'}
                                type={'text'}
                                onChange={(e) => { setInfoService({ ...infoService, nombres: e.target.value }) }}
                                readOnly={true}
                                disabled={true}
                                required={true}
                            />
                        </div>
                        <div className="col-sm-6 col-md-3 mt-3">
                            <MainInput
                                title={'Telefono'}
                                value={infoService.telefono}
                                name={'telefono'}
                                type={'text'}
                                onChange={(e) => { setInfoService({ ...infoService, telefono: e.target.value }) }}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainInput
                                title={'Correo electronico'}
                                value={infoService.email}
                                name={'email'}
                                type={'email'}
                                onChange={(e) => { setInfoService({ ...infoService, email: e.target.value }) }}
                                required={true}
                            />
                        </div>
                        <div className="col-md-6 mt-3">
                            <MainInput
                                title={'Correos adicionales (opcional)'}
                                value={infoService.emailAdd}
                                name={'emailAdd'}
                                type={'text'}
                                onChange={(e) => { setInfoService({ ...infoService, emailAdd: e.target.value }) }}
                            />
                        </div>
                        <div className="col-md-12 mt-4 mb-2 d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary">Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
            <Loader
                open={openLoader}
                message={messageLoader}
            />
        </div>
    )
}

export default FormBillingExternalServices